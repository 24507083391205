import { request } from '@/utils'

// 添加飞机类型
export const add_info = data => request.post('/api/aircrafttype/', data)


// 获取飞机类型
export const get_list = params => request.get('/api/aircrafttype/', { params })


// 获取飞机类型详细信息
export const get_info = id => request.get(`/api/aircrafttype/${id}/`)


// 修改飞机类型
export const edit_info = data => request.patch(`/api/aircrafttype/${data.id}/`, data)


// 删除飞机类型
export const del_info = id => request.delete(`/api/aircrafttype/${id}/`)
