<template>
    <div :is="layout">
        <v-container class="wrapper" fluid>
            <v-row class="wrapper__header">
                <v-col cols="3">
                    <v-select
                            :label="$t('aircraft.search')"
                            v-model="search_info.type"
                            :items="aircraftType_list_items"
                            item-value="id"
                            item-text="name"
                            @keydown.enter="doGet(1)"
                    ></v-select>
                </v-col>
                <v-col>
                    <v-btn class="wrapper__header__searchbtn" color="accent" @click="doGet(1)" large>{{ $t("search") }}</v-btn>
                    <v-btn
                            class="wrapper__hearder__addbtn"
                            color="accent"
                            large
                            @click="add_open"
                    >
                        添加飞机
                    </v-btn>
                </v-col>
            </v-row>
            <v-data-table
                    calculate-widths
                    :headers="headers"
                    :items="desserts"
                    class="elevation-1"
                    :loading="loading"
                    :page="search_info.pageindex"
                    :loading-text="$t('data_loading')"
                    @pagination="changePage"
                    style="background: var(--v-secondary-base); height: 651px"
                    :server-items-length="list_count"
            >
                <template v-slot:top>
                    <v-toolbar style="background: var(--v-secondary-base)" flat>
                        <v-toolbar-title>{{ $t("aircraft.title") }}</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                    </v-toolbar>
                </template>
                <template v-slot:item.manufacturer="{item}">
                    {{aircraftManufacturer_list[item.manufacturer]}}
                </template>
                <template v-slot:item.type="{item}">
                    {{aircraftType_list[item.type]}}
                </template>
                <template v-slot:item.date_manufacture='{item}'>
                    {{formateDate(item.date_manufacture*1000)}}
                </template>
                <template v-slot:[`item.status`]="{ item }">
                    <div v-if="item.status == '1'">{{$t('aircraft.normal')}}</div>
                    <div v-if="item.status == '0'">{{$t('aircraft.abnormal')}}</div>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-btn
                            color="accent"
                            @click="edit_open(item)" small text
                    >
                        {{$t('edit')}}
                    </v-btn>
                    <v-btn color="accent" small @click.stop="del(item.id)" text>
                        {{$t('delect')}}
                    </v-btn>
                </template>
            </v-data-table>
            <v-row class="wrapper__bottom">
                <v-col cols="6">
                    <v-card style="background: var(--v-secondary-base); " class="wrapper__bottom__card">
                        <v-row class="wrapper__bottom__top">
                            <v-col cols="3">
                                <v-text-field
                                        @keypress.enter="doGet_aircraft_type(1)"
                                        v-model="search_info_aircraft_type.name"
                                        :label="$t('aircraft.AirCraftType')"
                                ></v-text-field>
                            </v-col>
                            <v-col>
                                <v-btn @click="doGet_aircraft_type(1)" large color="accent">{{
                                    $t("search")
                                    }}
                                </v-btn>

                                <v-btn @click="add_open_aircraft" class="wrapper__bottom__addbtn" color="accent" large>
                                    添加类型
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-data-table
                                :server-items-length="aircraft_type_list_count"
                                :headers="aircraft_type_headers"
                                :items="aircraft_type_list"
                                :items-per-page="10"
                                style="background: var(--v-secondary-base); "
                                :loading-text="$t('data_loading')"
                                :loading="table_loading"
                                :page="search_info_aircraft_type.pageindex"
                                @pagination="changePagination"
                                class="elevation-1"
                        >
                            <template v-slot:top>
                                <v-toolbar flat style="background: var(--v-secondary-base); ">
                                    <v-toolbar-title>{{ $t("aircraft.Type_title") }}</v-toolbar-title>
                                    <v-divider class="mx-4" inset vertical></v-divider>
                                </v-toolbar>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-btn @click="edit_open_aircraft(item)" small color="accent" text
                                >{{$t('edit')}}
                                </v-btn
                                >
                                <v-btn @click="del_aircraft(item.id)" small color="accent" text> {{$t('delect')}}
                                </v-btn>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-col>
                <v-col cols="6" >
                    <v-card style="background: var(--v-secondary-base); " class="wrapper__bottom__card">
                        <v-row class="wrapper__bottom__top">
                            <v-col cols="3">
                                <v-text-field
                                        @keypress.enter="doGet_manufactures(1)"
                                        v-model="search_info_manufactures.name"
                                        :label="$t('aircraft.AirCraftManufacturer')"
                                ></v-text-field>
                            </v-col>
                            <v-col>
                                <v-btn @click="doGet_manufactures(1)" large color="accent">{{
                                    $t("search")
                                    }}
                                </v-btn>
                                <v-btn @click="add_open_manufactures" class="wrapper__bottom__addbtn" large
                                       color="accent">
                                    添加厂商
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-data-table
                                :server-items-length="list_count_manufactures"
                                :headers="headers_manufactures"
                                :items="list_data_manufactures"
                                :items-per-page="10"
                                style="background: var(--v-secondary-base); "
                                :loading-text="$t('data_loading')"
                                :page="search_info_manufactures.pageindex"

                                :loading="table_loading_manufactures"
                                @pagination="changePaginationManufactures"
                                class="elevation-1"
                        >
                            <template v-slot:top>
                                <v-toolbar flat style="background: var(--v-secondary-base); ">
                                    <v-toolbar-title>{{
                                        $t("aircraft.Manufacturer_title")
                                        }}
                                    </v-toolbar-title>
                                    <v-divider class="mx-4" inset vertical></v-divider>
                                </v-toolbar>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-btn @click="edit_open_manufactures(item)" small color="accent" text
                                >{{$t('edit')}}
                                </v-btn
                                >
                                <v-btn @click="del_manufactures(item.id)" small color="accent" text> {{$t('delect')}}
                                </v-btn>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
            <v-dialog v-model="dialog_manufactures" width="800px">
                <v-card>
                    <v-card-title>{{
                        $t(
                        edit_mode_manufactures
                        ? "aircraft.edit_manufacturer"
                        : "aircraft.add_manufacturer"
                        )
                        }}
                    </v-card-title>
                    <v-container class="pd-30">
                        <v-row>
                            <v-col cols="8">
                                <v-text-field
                                        v-model="info_manufactures.name"
                                        :label="$t('aircraft.Manufacturer_name')"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="submit_manufactures" color="accent">{{ $t("confirm") }}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialog_aircraft" width="800px">
                <v-card>
                    <v-card-title>{{
                        $t(edit_mode_aircraft ? "aircraft.edit_type" : "aircraft.add_type")
                        }}
                    </v-card-title>
                    <v-container class="pd-30">
                        <v-row>
                            <v-col cols="8">
                                <v-text-field
                                        v-model="info_aircraft.name"
                                        :label="$t('aircraft.Type_name')"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="submit_aircraft" color="accent">{{ $t("confirm") }}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-container>
        <v-dialog v-model="dialog" width="800px">
            <v-card style="background: var(--v-secondary-base)">
                <v-card-title>{{$t(edit_mode ? 'aircraft.edit_aircraft' : 'aircraft.add_aircraft')}}</v-card-title>
                <v-container class="pd-30">
                    <v-row>

                        <v-col cols="8">
                            <v-text-field
                                    v-model="listData.name"
                                    :label="$t('aircraft.name')"
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="8">
                            <v-select
                                    v-model="listData.type"
                                    :items="aircraftType_list_items"
                                    item-text="name"
                                    item-value="id"
                                    :label="$t('aircraft.Type')"
                                    required
                            ></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-btn @click="open_add_type_dialog" x-small depressed color="accent" fab>
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="8">
                            <v-select
                                    :items="aircraftManufacturer_list_items"
                                    item-value="id"
                                    item-text="name"
                                    v-model="listData.manufacturer"
                                    :label="$t('aircraft.Manufacturer')"
                                    required
                            ></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-btn @click="open_add_manufacturer_dialog" x-small depressed color="accent" fab>
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                    v-model="listData.min_velocity"
                                    :label="$t('aircraft.Min_velocity')"
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                    v-model="listData.max_velocity"
                                    :label="$t('aircraft.Max_velocity')"
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                    v-model="listData.avg_velocity"
                                    :label="$t('aircraft.Avg_velocity')"
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-menu
                                    v-model="menu2"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                            v-model="listData.date_manufacture"
                                            :label="$t('aircraft.Date_manufacture')"
                                            required
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                        v-model="listData.date_manufacture"
                                        @input="menu2 = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                    v-model="listData.count_seat"
                                    :label="$t('aircraft.Count_seat')"
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                    v-model="listData.customer_service"
                                    :label="$t('aircraft.Customer_service')"
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                    v-model="listData.mileage"
                                    :label="$t('aircraft.Mileage')"
                                    required
                            ></v-text-field>
                        </v-col>

                        <v-col cols="6">
                            <v-text-field
                                    v-model="listData.size"
                                    :label="$t('aircraft.Size')"
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                    v-model="listData.flight_time"
                                    :label="$t('aircraft.Flight_time')"
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                    v-model="listData.max_fuel"
                                    :label="$t('aircraft.Max_fuel')"
                                    required
                            ></v-text-field>
                        </v-col>

                        <v-col cols="6">
                            <v-text-field
                                    v-model="listData.max_luggage"
                                    :label="$t('aircraft.Max_luggage')"
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                    v-model="listData.furthest"
                                    :label="$t('aircraft.Furthest')"
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-select
                                    v-model="listData.status"
                                    :items="items"
                                    item-text="state"
                                    item-value="abbr"
                                    :label="$t('aircraft.Status')"
                                    required
                            ></v-select>
                        </v-col>
                    </v-row>
                </v-container>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="submit" color="accent">{{$t('confirm')}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog width="300px" v-model="add_type_dialog">
            <v-card>
                <v-card-title>{{$t('aircraft.add_type')}}</v-card-title>
                <v-container style="padding: 30px">
                    <v-text-field v-model="add_type_info.name" :label="$t('aircraft.type_name')"></v-text-field>
                </v-container>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="submit_add_type" text>{{$t('confirm')}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog width="300px" v-model="add_manufacturer_dialog">
            <v-card>
                <v-card-title>{{$t('aircraft.add_manufacturer')}}</v-card-title>
                <v-container style="padding: 30px">
                    <v-text-field v-model="add_manufacturer_info.name"
                                  :label="$t('aircraft.manufacturer_name')"></v-text-field>
                </v-container>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="submit_add_manufacturer" text>{{$t('confirm')}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
    import {get_list, del_info, add_info, edit_info} from "../../../api/aircraft";
    import {get_list as get_aircraftManufacturer_list} from "../../../api/aircraftManufacturer";
    import {get_list as get_aircraftType_list} from "../../../api/aircraftType";
    import {
        add_info as add_type_info,
        del_info as del_info_aircraft,
        add_info as add_info_aircraft,
        edit_info as edit_info_aircraft
    } from '../../../api/aircraftType'
    import {
        add_info as add_manufactures,
        get_list as get_list_manufactures,
        edit_info as edit_info_manufactures,
        del_info as del_info_manufactures
    } from "@/api/aircraftManufacturer";

    export default {
        data() {
            return {
                edit_mode_aircraft: false,
                add_type_dialog: false,
                add_type_info: {
                    name: '',
                },
                aircraft_type_list: [],
                add_manufacturer_dialog: false,
                add_manufacturer_info: {
                    name: '',
                },
                airport_id: '',
                dialog: false,
                layout: null,
                edit_mode: false,
                table_loading: false,
                desserts: [],
                searchData: "",
                loading: false,
                search_info: {
                    pageindex: 1,
                    pagesize: 10,
                    type: ""
                },
                list_count: 0,
                menu2: false,
                list: [],
                aircraftType_list_items: [],
                aircraftManufacturer_list_items: [],
                aircraftType_list: [],
                aircraft_type_list_count: 0,
                aircraftManufacturer_list: [],
                items: [
                    {state: this.$t('aircraft.normal'), abbr: 1},
                    {state: this.$t('aircraft.abnormal'), abbr: 0},
                ],
                listData: {
                    id: "",
                    type: "",
                    manufacturer: "",
                    name: '',
                    min_velocity: "",
                    max_velocity: "",
                    avg_velocity: "",
                    date_manufacture: "",
                    count_seat: "",
                    customer_service: "",
                    mileage: "",
                    size: "",
                    flight_time: "",
                    max_fuel: "",
                    max_luggage: "",
                    furthest: "",
                    status: "",
                },
                index: "",
                add_loading: false,
                table_loading_manufactures: false,
                search_info_aircraft_type: {
                    pageindex: 1,
                    pagesize: 10,
                    name: "",
                },
                info_aircraft: {
                    name: "",
                    craete_time: "",
                    update_time: "",
                },
                dialog_aircraft: false,
                search_info_manufactures: {
                    name: "",
                    craete_time: "",
                    update_time: "",
                },
                list_data_manufactures: [],
                list_count_manufactures: 0,
                dialog_manufactures: false,
                info_manufactures: {
                    name: "",
                    craete_time: "",
                    update_time: "",
                },
                edit_mode_manufactures: false,

            };
        },
        methods: {

            del_manufactures(id) {
                this.$confirm({
                    title: '确认删除',
                    content: '确认删除该厂商吗',
                    okText: "确认",
                    cancelText: "取消",
                })
                    .then((dialog) => {
                        del_info_manufactures(id).then((res) => {
                            this.doGet_manufactures();
                        });
                    });
            },
            add_open_manufactures() {
                this.edit_mode_manufactures = false;
                this.info_manufactures = {
                    name: "",
                    // craete_time: "",
                    // update_time: "",
                };
                this.dialog_manufactures = true;
            },
            submit_manufactures() {
                if (this.edit_mode_manufactures) {
                    edit_info_manufactures(this.info_manufactures).then((res) => {
                        this.$toast({
                            title: this.$t('success'),
                            message: "修改飞机厂商成功",
                        });
                    })
                        .finally(() => {
                            this.dialog_manufactures = false
                            this.doGet_manufactures();
                        });
                } else {
                    add_manufactures(this.info_manufactures).then((res) => {
                        this.$toast({
                            title: this.$t('success'),
                            message: "添加飞机厂商成功",
                        });
                    })
                        .finally(() => {
                            this.dialog_manufactures = false
                            this.doGet_manufactures();
                        });
                }
            },
            edit_open_manufactures(data) {
                this.edit_mode_manufactures = true;
                this.info_manufactures = {}
                // this.info = JSON.parse(JSON.stringify(data));
                this.info_manufactures.name = data.name
                this.info_manufactures.id = data.id
                this.dialog_manufactures = true;
            },
            changePaginationManufactures(data) {
                this.search_info_manufactures.pageindex = data.page;
                this.search_info_manufactures.pagesize = data.itemsPerPage;
                this.doGet_manufactures();
            },
            doGet_manufactures(pageindex = this.search_info_manufactures.pageindex) {
                this.search_info_manufactures.pageindex = pageindex;
                this.table_loading_manufactures = true;
                get_list_manufactures(this.search_info_manufactures)
                    .then((res) => {
                        this.list_data_manufactures = res.results;
                        this.list_count_manufactures = res.count;
                    })
                    .finally(() => {
                        this.table_loading_manufactures = false;
                    });
            },
            add_open_aircraft() {
                this.edit_mode_aircraft = false;
                this.info_aircraft = {
                    name: "",
                    // craete_time:"",
                    // update_time:""
                };
                this.dialog_aircraft = true;
            },
            del_aircraft(id) {
                this.$confirm({
                    title: this.$t('confirm_del'),
                    content: this.$t('aircraft.delete'),
                    okText: this.$t('confirm'),
                    cancelText: this.$t('cancel'),
                })
                    .then((dialog) => {
                        del_info_aircraft(id).then((res) => {
                            this.doGet_aircraft_type();
                        });
                    });
            },
            submit_aircraft() {
                this.add_loading = true;
                if (this.edit_mode_aircraft) {
                    edit_info_aircraft(this.info_aircraft)
                        .then((res) => {
                            this.$toast({
                                title: this.$t('success'),
                                message: this.$t('aircraft.edit_success'),
                            });
                            this.dialog_aircraft = false;
                        })
                        .finally(() => {
                            this.doGet_aircraft_type();
                        });
                } else {
                    add_info_aircraft(this.info_aircraft)
                        .then((res) => {
                            this.$toast({
                                title: this.$t('success'),
                                message: this.$t('aircraft.add_success'),
                            });
                            this.dialog_aircraft = false;
                        })
                        .finally(() => {
                            this.doGet_aircraft_type();
                        });
                }
            },
            edit_open_aircraft(data) {
                this.edit_mode_aircraft = true;
                this.info_aircraft = {}
                this.info_aircraft.name = data.name
                this.info_aircraft.id = data.id
                this.dialog_aircraft = true;
            },
            changePagination(data) {
                this.search_info_aircraft_type.pageindex = data.page;
                this.search_info_aircraft_type.pagesize = data.itemsPerPage;
                this.doGet_aircraft_type();
            },
            doGet_aircraft_type(pageindex = this.search_info_aircraft_type.pageindex) {
                this.search_info_aircraft_type.pageindex = pageindex;
                this.table_loading = true;
                get_aircraftType_list(this.search_info_aircraft_type)
                    .then((res) => {
                        this.aircraft_type_list = res.results;
                        this.aircraft_type_list_count = res.count;
                        if (Math.ceil(res.count / 10) < this.pageindex - 1) {
                            this.pageindex--
                        }
                    })
                    .finally(() => {
                        this.table_loading = false;
                    });
            },
            submit_add_type() {
                add_type_info(this.add_type_info)
                    .then((res) => {
                        this.$toast({
                            title: this.$t('success'),
                            message: this.$t('aircraft.add_success'),
                        });
                        this.add_type_dialog = false;
                        get_aircraftType_list({pageindex: 1, pagesize: 9999}).then(res => {
                            this.aircraftType_list_items = res.results
                            const aircraftType_list = {}
                            for (let i of res.results) {
                                aircraftType_list[i.id] = i.name
                            }
                            this.aircraftType_list = aircraftType_list
                        })
                    })
            },
            submit_add_manufacturer() {
                add_manufactures(this.add_manufacturer_info)
                    .then((res) => {
                        this.$toast({
                            title: this.$t('success'),
                            message: "添加飞机厂商成功",
                        });
                        this.add_manufacturer_dialog = false;

                        get_aircraftManufacturer_list({pageindex: 1, pagesize: 9999}).then(res => {
                            this.aircraftManufacturer_list_items = res.results
                            const aircraftManufacturer_list = {}
                            for (let i of res.results) {
                                aircraftManufacturer_list[i.id] = i.name
                            }
                            this.aircraftManufacturer_list = aircraftManufacturer_list
                        })
                    })
            },
            open_add_type_dialog() {
                this.add_type_info = {
                    name: ''
                }
                this.add_type_dialog = true
            },
            open_add_manufacturer_dialog() {
                this.add_manufacturer_info = {
                    name: ''
                }
                this.add_manufacturer_dialog = true
            },
            changePage(e) {
                this.search_info.pagesize = e.itemsPerPage
                this.search_info.pageindex = e.page
                this.doGet()
            },
            del(id) {
                this.$confirm({
                    title: this.$t('confirm_del'),
                    content: this.$t('aircraft.delete_aircraft'),
                    okText: this.$t('confirm'),
                    cancelText: this.$t('cancel'),
                })
                    .then((dialog) => {
                        del_info(id).then(res => {
                            this.doGet()
                        })
                    })
            },
            doGet(pageindex = this.search_info.pageindex) {
                this.search_info.pageindex = pageindex
                this.loading = true
                get_list({...this.search_info}).then(res => {
                    this.desserts = res.results
                    this.list_count = res.count
                }).finally(() => {
                    this.loading = false
                })
            },
            add_open() {
                this.edit_mode = false
                this.listData = {
                    type: "",
                    manufacturer: "",
                    min_velocity: "",
                    max_velocity: "",
                    avg_velocity: "",
                    date_manufacture: "",
                    count_seat: "",
                    customer_service: "",
                    mileage: "",
                    size: "",
                    flight_time: "",
                    max_fuel: "",
                    max_luggage: "",
                    furthest: "",
                    status: "",
                }
                this.dialog = true
            },
            edit_open(data) {
                this.edit_mode = true
                this.listData = JSON.parse(JSON.stringify(data))
                this.listData.date_manufacture = this.formateDate(data.date_manufacture * 1000)
                this.dialog = true
            },
            submit() {
                this.add_loading = true
                if (this.edit_mode) {
                    // const date_manufacture= this.listData.date_manufacture
                    // this.listData.date_manufacture=new Date(date_manufacture).getTime() / 1000
                    this.list = JSON.parse(JSON.stringify(this.listData))
                    this.list.date_manufacture = new Date(this.listData.date_manufacture).getTime() / 1000
                    for (let i in this.list) {
                        if (this.list[i] === '') {
                            this.list[i] = null
                        }
                    }
                    edit_info({...this.list, airport_id: this.airport_id}).then(res => {
                        this.$toast({
                            title: this.$t('success'),
                            message: this.$t('aircraft.edit_success_aircraft')
                        })
                        this.dialog = false
                    }).finally(() => {
                        this.add_loading = false
                        this.doGet()
                    })
                } else {
                    add_info({...this.listData, airport_id: this.airport_id}).then(res => {
                        this.$toast({
                            title: this.$t('success'),
                            message: this.$t('aircraft.add_success_aircraft')
                        })
                        this.dialog = false
                    }).finally(() => {
                        this.add_loading = false
                        this.doGet()
                    })
                }
            },
            formateDate(datetime) {
                function addDateZero(num) {
                    return num < 10 ? "0" + num : num;
                }

                let d = new Date(datetime);
                let formatdatetime =
                    d.getFullYear() +
                    "-" +
                    addDateZero(d.getMonth() + 1) +
                    "-" +
                    addDateZero(d.getDate())
                return formatdatetime;
            },
        },
        mounted() {
            this.layout = this.$route.meta.use_layout
            // 不用验证机场
            // if (!this.$route.query.airport_id && !this.$store.state.select_air_port_id) {
            //     this.$toast({
            //         title: this.$t('error'),
            //         message: this.$t('flight.error_mes'),
            //         type: 'warning'
            //     })
            //     return this.$router.push('/')
            // }
            // this.airport_id = this.$route.query.airport_id || this.$store.state.select_air_port_id
            // this.doGet_aircraft_type(this.search_info_aircraft_type).then(res => {
            //     console.log(res)
            // })
            get_aircraftManufacturer_list({pageindex: 1, pagesize: 9999}).then(res => {
                this.aircraftManufacturer_list_items = res.results
                const aircraftManufacturer_list = {}
                for (let i of res.results) {
                    aircraftManufacturer_list[i.id] = i.name
                }
                this.aircraftManufacturer_list = aircraftManufacturer_list
            })
            get_aircraftType_list({pageindex: 1, pagesize: 9999}).then(res => {
                this.aircraftType_list_items = res.results
                const aircraftType_list = {}
                for (let i of res.results) {
                    aircraftType_list[i.id] = i.name
                }
                this.aircraftType_list = aircraftType_list
            })
        },
        computed: {
            headers_manufactures() {
                return [
                    {
                        text: this.$t("aircraft.Manufacturer_name"),
                        align: "center",
                        value: "name",
                        sortable: false,
                    },
                    {
                        text: this.$t("aircraft.createTime"),
                        align: "center",
                        value: "create_time",
                        sortable: false,
                    },
                    {
                        text: this.$t("aircraft.updateTime"),
                        align: "center",
                        value: "update_time",
                        sortable: false,
                    },
                    {
                        text: this.$t("aircraft.Action"),
                        value: "actions",
                        sortable: false,
                        align: "center",
                    },
                ];
            },
            aircraft_type_headers() {
                return [
                    {
                        text: this.$t("aircraft.Type_name"),
                        align: "center",
                        value: "name",
                        sortable: false,
                    },
                    {
                        text: this.$t("aircraft.createTime"),
                        align: "center",
                        value: "create_time",
                        sortable: false,
                    },
                    {
                        text: this.$t("aircraft.updateTime"),
                        align: "center",
                        value: "update_time",
                        sortable: false,
                    },
                    {
                        text: this.$t("aircraft.Action"),
                        value: "actions",
                        sortable: false,
                        align: "center",
                    },
                ];
            },
            headers() {
                return [
                    {
                        text: this.$t("aircraft.name"),
                        align: "center",
                        sortable: false,
                        value: "name",
                    },
                    {
                        text: this.$t("aircraft.Type"),
                        align: "center",
                        sortable: false,
                        value: "type",
                    },
                    {
                        text: this.$t("aircraft.Manufacturer"),
                        value: "manufacturer",
                        sortable: false,
                        align: "center",
                    },
                    {
                        text: this.$t("aircraft.Status"),
                        value: "status",
                        align: "center",
                        sortable: false,
                    },
                    {
                        text: this.$t("aircraft.Action"),
                        value: "actions",
                        sortable: false,
                        align: "center",
                    },
                ];
            },
        },
    };
</script>
<style lang="stylus" scoped>
    @import '~@/assets/css/global.styl';
    .wrapper
        &__header
            &__searchbtn
                margin-right: 10px;
                background: var(--v-secondary-base)

            &__addbtn
                background: var(--v-secondary-base)

        &__bottom
            margin-top 20px

            &__top
                padding 0 20px 0 20px

            &__addbtn
                margin-left 10px

            &__card
                height 733px
</style>
