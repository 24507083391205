import { request } from '@/utils'

// 添加飞机厂商
export const add_info = data => request.post('/api/aircraftmanufacturer/', data)


// 获取飞机厂商列表
export const get_list = params => request.get('/api/aircraftmanufacturer/', { params })


// 获取飞机厂商详细信息
export const get_info = id => request.get(`/api/aircraftmanufacturer/${id}/`)


// 修改飞机厂商
export const edit_info = data => request.patch(`/api/aircraftmanufacturer/${data.id}/`, data)


// 删除飞机厂商
export const del_info = id => request.delete(`/api/aircraftmanufacturer/${id}/`)
